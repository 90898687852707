import React, { createContext, useState } from "react"

const UIStateContext = createContext({ state: {}, action: {} })
UIStateContext.displayName = "UIStateContext"

const UIStateProvider = ({ children }) => {
  const [toggleDest, setToggleDest] = useState(false)

  const state = { toggleDest }

  const action = { setToggleDest }

  return (
    <UIStateContext.Provider value={{ state, action }}>
      {children}
    </UIStateContext.Provider>
  )
}

const UIStateConsumer = UIStateContext.Consumer

export { UIStateProvider, UIStateConsumer }
export default UIStateContext
