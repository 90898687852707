import React from "react"
import "./src/styles/normalize.css"
import "./src/styles/global.scss"
import { UIStateProvider } from "./src/context/UIStateContext"

require("typeface-noto-sans-kr")

export const wrapRootElement = ({ element }) => (
  <UIStateProvider>{element}</UIStateProvider>
)
